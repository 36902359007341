import React from 'react';

type TotalTableProps = {
    title: string;
    value: string;
};

const TotalTable = ({ title, value }: TotalTableProps) => {
    return (
        <table style={{ width: '100%' }}>
            <tbody>
                <tr>
                    <td style={{ textAlign: 'left' }}>{title}</td>
                    <td style={{ textAlign: 'right', verticalAlign: 'top' }}>{value}</td>
                </tr>
            </tbody>
        </table>
    );
};

export default TotalTable;
