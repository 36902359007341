import { useCallback } from 'react';
import { DefaultError, useQuery } from '@tanstack/react-query';
import { getOrderApi } from '../api/orders';
import { OrderModel } from '../models';
import { OrderData } from '../types/orderDataType';
import { findOrder } from '../helpers/order/findOrder';
import useRestaurant from './useRestaurant';
import queryClient from '../services/query/queryClient';
import useOrders from './useOrders';

export default (id: number | null) => {
    const restaurant = useRestaurant();
    const { isFetching } = useOrders();

    return useQuery<OrderData | null, DefaultError, OrderModel | undefined>({
        queryKey: ['orders', 'details', id],
        queryFn: () => {
            if (!id) return null;

            const orders = queryClient.getQueryData<OrderData[]>(['orders', restaurant.id]) || [];
            return findOrder(orders, id) ?? getOrderApi(id);
        },
        select: useCallback((data: OrderData | null) => (data ? new OrderModel(data) : undefined), []),
        enabled: !isFetching
    });
};
