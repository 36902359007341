import { utcToZonedTime } from 'date-fns-tz';
import { CASH, PINATHOME, CREDIT_CARD_AT_HOME, CHIPKNIP, CHEQUE, MEAL_VOUCHER, BANCONTACT, ONLINE } from '../constants';
import i18n from '../localization/i18n';
import { RestaurantModel } from '../models';
import { Product, Specification } from '../types/orderDataType';

export const formatTime = (date: Date | null, restaurantTimezone: string) => {
    return date && utcToZonedTime(date, restaurantTimezone).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
};

export const formatTotal = (total: number, currency: string) => {
    return Intl.NumberFormat([], { style: 'currency', currency }).format(total);
};

export const getItemCode = (item: Product | Specification, restaurant: RestaurantModel) => {
    if (!restaurant.receipt_settings.product_id_enabled) {
        return null;
    }

    let code = restaurant.gtins_enabled && item.gtin ? item.gtin : item.code;

    if (code) {
        code = code.slice(0, restaurant.receipt_settings.product_id_length || 5);
    }

    return code ? `(#${code})` : null;
};

export const getPaymentTypeTitle = (paymentType: string) => {
    switch (paymentType) {
        case CASH:
            return i18n.t('orders.live_orders_receipt.main.payment_cash');
        case PINATHOME:
            return i18n.t('orders.live_orders_receipt.main.payment_pinathome');
        case CREDIT_CARD_AT_HOME:
            return i18n.t('orders.live_orders_receipt.main.payment_mobilecreditcard');
        case CHIPKNIP:
            return i18n.t('orders.live_orders_receipt.main.payment_chipknip');
        case CHEQUE:
            return i18n.t('orders.live_orders_receipt.main.payment_cheque');
        case MEAL_VOUCHER:
            return i18n.t('orders.live_orders_receipt.main.payment_mealvoucher');
        case BANCONTACT:
            return i18n.t('orders.live_orders_receipt.main.payment_bancontact');
        case ONLINE:
            return i18n.t('orders.live_orders_receipt.main.payment_online');
        default:
            return '';
    }
};
