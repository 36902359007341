import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@jet-pie/react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router';
import { useIsChainAccount } from '@lo/shared/store/auth';
import useOrderDetails from '@lo/shared/hooks/useOrderDetails';
import Size58mm from './Size58mm/Size58mm';
import classes from './Receipt.module.scss';

type ParamTypes = {
    id: string;
};

const Receipt: React.FC = () => {
    const { id } = useParams<ParamTypes>();
    const { t } = useTranslation();
    const isChainAccount = useIsChainAccount();
    const { data: order } = useOrderDetails(parseInt(id));
    const history = useHistory();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return isChainAccount ? (
        <Redirect
            to={{
                pathname: `/orders`
            }}
        />
    ) : (
        <Fragment>
            <div className={classes.buttons}>
                <button className={classes.backLink} onClick={() => history.push('/orders')}>
                    Back to orders
                </button>
                <Button disabled={!order} size="small-expressive" onClick={window.print}>
                    {t('orders.live_orders_order_details.actions.receipt_print')}
                </Button>
            </div>

            {order && <Size58mm order={order} onLoad={() => setTimeout(window.print, 500)} />}
        </Fragment>
    );
};

export default Receipt;
