import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useRestaurant from '@lo/shared/hooks/useRestaurant';
import { formatTime, formatTotal, getPaymentTypeTitle } from '@lo/shared/helpers/receipt';
import { CASH, ONLINE } from '@lo/shared/constants';
import { OrderModel } from '@lo/shared/models';
import config from '@lo/shared/helpers/config';
import ProductsTable from './ProductsTable/ProductsTable';
import { Product } from '@lo/shared/types/orderDataType';
import TotalTable from './TotalTable';
import logoSrc from '../../../static/images/icon-black.svg';
import classes from './Size58mm.module.scss';

type Size58mmProps = {
    order: OrderModel;
    onLoad: () => void;
};

const Size58mm: FC<Size58mmProps> = ({ order, onLoad }) => {
    const restaurant = useRestaurant();
    const { t } = useTranslation();
    const [isLoadingQR, setIsLoadingQR] = useState(restaurant.is_own_delivery);

    useEffect(() => {
        if (!isLoadingQR) {
            onLoad();
        }
    }, [isLoadingQR]);

    const [availableProducts, unavailableProducts] = order.products.reduce(
        (acc, product) => {
            if (product.is_available) {
                acc[0].push(product);
            } else {
                acc[1].push(product);
            }

            return acc;
        },
        [[], []] as [Product[], Product[]]
    );

    return (
        <div
            className={classes.container}
            data-testid="receipt-content"
            style={{ visibility: isLoadingQR ? 'hidden' : 'visible' }}
        >
            <img width={50} height={50} src={logoSrc} alt="Logo" />

            <p data-testid="restaurant-address">
                {restaurant.name}, {restaurant.street} {restaurant.street_number}, {restaurant.postcode}
            </p>

            <p>
                {restaurant.city}, {t('orders.live_orders_receipt.main.tel')}: {restaurant.phone_number}
            </p>

            <h3 data-testid="order-reference">{order.public_reference}</h3>

            <p data-testid="placed-date">
                {order.placed_date.toLocaleDateString()} {formatTime(order.placed_date, restaurant.timezone)}
            </p>

            <div className={classes.divider} />

            <h2>
                {order.is_delivery
                    ? t('orders.live_orders_order_list.delivery_types.delivery')
                    : t('orders.live_orders_order_list.delivery_types.pick_up')}
            </h2>

            <h2>
                {restaurant.is_own_delivery
                    ? t('orders.live_orders_receipt.main.confirm_time')
                    : t('orders.live_orders_receipt.main.pickup_eta')}
            </h2>

            <h2 data-testid="delivery-time">
                {restaurant.is_own_delivery
                    ? formatTime(order.restaurant_estimated_delivery_time, restaurant.timezone)
                    : formatTime(order.delivery_service_pickup_time, restaurant.timezone)}
            </h2>

            {order.customer && (
                <div className={classes.left} data-testid="customer-info">
                    <p>{order.customer.full_name}</p>
                    {restaurant.is_own_delivery && (
                        <>
                            <p>
                                {order.customer.street} {order.customer.street_number}
                            </p>
                            <p>
                                {order.customer.postcode} {order.customer.city}
                            </p>
                        </>
                    )}
                    {order.customer.phone_number && (
                        <p>
                            {t('orders.live_orders_receipt.main.tel')}: {order.customer.phone_number}
                        </p>
                    )}
                </div>
            )}

            <div className={classes.divider} />

            <ProductsTable products={availableProducts} currency={order.currency} />

            <div className={classes.divider} />

            {unavailableProducts.length > 0 && (
                <>
                    <p className={classes.left} style={{ margin: '4px' }}>
                        <b>{t('orders.live_orders_order_details.unavailable_items.button_title')}:</b>
                    </p>
                    <ProductsTable products={unavailableProducts} currency={order.currency} />
                    <div className={classes.divider} />
                </>
            )}

            {order.service_fee > 0 && (
                <TotalTable
                    title={t('orders.live_orders_order_details.titles.service_fee')}
                    value={formatTotal(order.service_fee, order.currency)}
                />
            )}

            <TotalTable
                title={t('orders.live_orders_order_details.titles.total')}
                value={formatTotal(order.customer_total, order.currency)}
            />

            <div className={classes.divider} />

            {order.with_alcohol && (
                <>
                    <p data-testid="alcohol-warning">{t('orders.live_orders_receipt.main.alcohol_warning')}</p>
                    <div className={classes.divider} />
                </>
            )}

            {order.remarks && (
                <>
                    <p className={classes.left} data-testid="remarks">
                        <b>{t('orders.live_orders_receipt.main.comments')}:</b> {order.remarks.trim()}
                    </p>
                    <div className={classes.divider} />
                </>
            )}

            {config.release && <p className={classes.left}>v{config.release}</p>}

            <p>
                <u>{t('orders.live_orders_receipt.main.important')}:</u>
            </p>

            <h2>
                {order.payment_type === ONLINE
                    ? t('orders.live_orders_receipt.main.order_paid_online')
                    : `${t('orders.live_orders_receipt.main.order_has_not')} ${t('orders.live_orders_receipt.main.been_paid')}`}
            </h2>

            <h4>
                {t('orders.live_orders_receipt.main.payment', 'Payment')}: {getPaymentTypeTitle(order.payment_type)}
            </h4>

            {order.payment_type === CASH && order.payment?.pays_with && (
                <h4>
                    {t('orders.live_orders_order_details.titles.pays_with')}{' '}
                    {formatTotal(Number(order.payment.pays_with), order.currency)}
                </h4>
            )}

            {restaurant.is_own_delivery && (
                <>
                    <br />
                    <img
                        src={`${config.apiUrl}/orders/${order.id}/qr.svg`}
                        width="60%"
                        alt="QR"
                        onLoad={() => setIsLoadingQR(false)}
                    />
                    <p>{t('orders.live_orders_receipt.main.scan_the_qr')}</p>
                </>
            )}

            <div className={classes.divider} />

            <p>{t('orders.live_orders_receipt.main.not_a_bill')}</p>
        </div>
    );
};

export default Size58mm;
